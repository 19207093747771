import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {
  audioSrc!:string
  constructor(
    private router:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.audioSrc=this.router.snapshot.queryParams['path']
    console.log(this.audioSrc)
  }

}
