import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {
  pdfSrc!:string
  constructor(
    private router:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.pdfSrc=this.router.snapshot.queryParams['path']
    console.log(this.pdfSrc)
  }

}
