<ng-template [ngIf]="materialId">
  <video
    controls="controls"
    id="videoPlayer"
    [poster]="isDefault==='true' ? '../../assets/thumbnail.png' : ''"
    (loadeddata)="updateSeekBar()"
    (play)="payVideo()"
    (pause)="pauseVideo()"
    [src]="path"
    style="height: 100%; width: 100%; position: fixed"
    playsinline
  >
    Your browser does not support the HTML5 Video element.
  </video>
</ng-template>
<ng-template [ngIf]="!materialId">
  <video
    controls="controls"
    [src]="path"
    [poster]="isDefault==='true' ? '../../assets/thumbnail.png' : ''"
    style="height: 100%; width: 100%; position: fixed"
    playsinline
  >
    Your browser does not support the HTML5 Video element.
  </video>
</ng-template>
