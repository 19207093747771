import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http:HttpClient) { }

  saveVideoMaterialDetails(details: any, accessToken: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
    return this.http.post(
      `${environment.apiEndpoint}/users/v2/api/saveVideoMaterialDetails`,
      details,
      { headers: headers }
    );
  }
  
}
