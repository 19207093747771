import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { PdfComponent } from './pdf/pdf.component';
import { VideoComponent } from './video/video.component';
import { AudioComponent } from './audio/audio.component';

const routes: Routes = [
  {
    path:'material/video',
    component:VideoComponent
  },
  {
    path:'material/pdf',
    component:PdfComponent
  },
  {
    path:'material/audio',
    component:AudioComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
