import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  path!: string;
  materialId!: number;
  videoResumeTime!: any;
  watchTime: number = 0;
  subscription!: Subscription;
  video!: HTMLVideoElement;
  token!:string;
  isDefault!:string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.materialId = this.activatedRoute.snapshot.queryParams['materialId'];
    this.path = this.activatedRoute.snapshot.queryParams['path'];
    this.videoResumeTime =
      +this.activatedRoute.snapshot.queryParams['videoResumeTime'];
    this.token=this.activatedRoute.snapshot.queryParams['accessToken']
    this.isDefault=this.activatedRoute.snapshot.queryParams['isDefault']
  }
  updateSeekBar() {
    this.video = document.getElementById('videoPlayer') as HTMLVideoElement;
    this.video.currentTime = this.videoResumeTime;
    this.video.ontimeupdate = this.videoResumeTime;
    console.log(this.videoResumeTime);
  }
  payVideo() {
    this.subscription = interval(100).subscribe((value: number) => {
      this.watchTime = value * 100;
    });
  }
  pauseVideo() {
    this.subscription.unsubscribe();
  }
  ngOnDestroy(): void {
    if (this.materialId) {
      this.subscription.unsubscribe();
      this.saveVideoDetails();
    }
  }
  @HostListener('window:beforeunload')
  beforeunloadHandler() {
    if (this.materialId) {
      this.subscription.unsubscribe();
      this.saveVideoDetails();
    }
  }
  saveVideoDetails() {
    console.log(this.watchTime / 1000);
    this.httpService
      .saveVideoMaterialDetails({
        materialId: this.materialId,
        watchTime: Math.round(this.watchTime / 1000),
        videoResumeTiming: this.video.currentTime,
      },
      this.token
      )
      .subscribe(
        (data: any) => {
          console.log('data saved successfully');
        },
        (error: any) => {
          console.error('something went wrong');
        }
      );
  }
}
